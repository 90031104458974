import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR, LIGHT_MODE } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'ControlMonkey';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const IAC_IMPORT_PATH = `${APP_PREFIX_PATH}/iacImport`;
export const NAMESPACE_LIST_PATH = `${APP_PREFIX_PATH}/namespaces`;
export const TEMPLATE_LIST_PATH = `${APP_PREFIX_PATH}/templates`;
export const DRIFT_CENTER_PATH = `${APP_PREFIX_PATH}/driftCenter`;
export const TF_MODULES_PATH = `${APP_PREFIX_PATH}/tfModules`;
export const TF_PROVIDERS_PATH = `${APP_PREFIX_PATH}/tfProviders`;
export const CLOUD_EVENTS_PATH = `${APP_PREFIX_PATH}/cloudEvents`;
export const CREATE_STACK_PATH = `${APP_PREFIX_PATH}/createStackWizard`;
export const STACK_DISCOVERY_VIEW_PATH = `${APP_PREFIX_PATH}/stackDiscovery`;
export const CREATE_STACK_DISCOVERY_PATH = `${APP_PREFIX_PATH}/createStackDiscoveryWizard`;
export const EDIT_STACK_DISCOVERY_PATH = `${APP_PREFIX_PATH}/editStackDiscoveryWizard`;
export const CREATE_STACK_FROM_TEMPLATE_PATH = `${APP_PREFIX_PATH}/createStackFromTemplateWizard`;
export const CREATE_STACK_FROM_BLUEPRINT_PATH = `${APP_PREFIX_PATH}/createStackFromBlueprintWizard`;
export const CREATE_TEMPLATE_PATH = `${APP_PREFIX_PATH}/createTemplateWizard`;
export const EDIT_TEMPLATE_PATH = `${APP_PREFIX_PATH}/editTemplateWizard`;
export const CREATE_BLUEPRINT_PATH = `${APP_PREFIX_PATH}/createBlueprintWizard`;
export const EDIT_BLUEPRINT_PATH = `${APP_PREFIX_PATH}/editBlueprintWizard`;
export const CREATE_NAMESPACE_PATH = `${APP_PREFIX_PATH}/createNamespaceWizard`;
export const EDIT_STACK_PATH = `${APP_PREFIX_PATH}/editStackWizard`;
export const EDIT_NAMESPACE_PATH = `${APP_PREFIX_PATH}/editNamespaceWizard`;
export const IMPORT_STACK_PATH = `${APP_PREFIX_PATH}/importStackWizard`;
export const IMPORT_TO_EXISTING_STACK_PATH = `${APP_PREFIX_PATH}/importToExistingStackWizard`;
export const CREATE_CUSTOM_MODEL_PATH = `${APP_PREFIX_PATH}/createModelWizard`;
export const STACK_VIEW_PATH = `${APP_PREFIX_PATH}/stack`;
export const NAMESPACE_VIEW_PATH = `${APP_PREFIX_PATH}/namespace`;
export const TEMPLATE_VIEW_PATH = `${APP_PREFIX_PATH}/template`;
export const TEAM_VIEW_PATH = `${APP_PREFIX_PATH}/team`;
export const DEPLOYMENT_VIEW_PATH = `${APP_PREFIX_PATH}/deployment`;
export const PLAN_VIEW_PATH = `${APP_PREFIX_PATH}/plan`;
export const TASK_VIEW_PATH = `${APP_PREFIX_PATH}/task`;
export const SETTINGS_PATH = `${APP_PREFIX_PATH}/organization/setting`;
export const RESOURCE_FINDER_PATH = `${APP_PREFIX_PATH}/resourceFinder`;
export const CLOUD_INVENTORY_PATH = `${APP_PREFIX_PATH}/cloudInventory`;
export const CONTROL_POLICY_GROUPS = `${APP_PREFIX_PATH}/controlPolicyGroup`;
export const SECURITY_STANDARD = `${APP_PREFIX_PATH}/securityStandard`;
export const VCS_PROVIDERS_PATH = `${SETTINGS_PATH}?id=vcs-providers`;
export const CLOUD_CREDENTIALS_PATH = `${SETTINGS_PATH}?id=cloud-creds`;
export const USERS_PATH = `${SETTINGS_PATH}?id=user-list`;
export const PROGRAMMATIC_USERS_PATH = `${SETTINGS_PATH}?id=programmatic-user-list`;
export const TEAMS_PATH = `${SETTINGS_PATH}?id=team-list`;
export const CUSTOM_ROLES_PATH = `${SETTINGS_PATH}?id=custom-roles`;
export const NOTIFICATION_ENDPOINTS_PATH = `${SETTINGS_PATH}?id=notification`;
export const AUTH_PREFIX_PATH = '/auth';
export const ENTRY_ROUTE = '/auth/login';
export const SIGNUP_ORGANIZATION_PATH = `${AUTH_PREFIX_PATH}/signup`;
export const AWS_MP_POST_REGISTRATION_PATH = `${AUTH_PREFIX_PATH}/postAwsMarketplaceRegistration`;
export const RECOVER_PASSWORD_PATH = `${AUTH_PREFIX_PATH}/recoverPassword`;
export const LOGIN_VIA_SSO_PATH = `${AUTH_PREFIX_PATH}/login/sso`;
export const LOGIN_VIA_SSO_CHOOSE_LOGIN_PATH = `${AUTH_PREFIX_PATH}/ssoChooseLogin`;

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: LIGHT_MODE,
	direction: DIR_LTR
};
