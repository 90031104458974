import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Row, Col, Card, List, Tag, Select, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import {
	showLoading,
	hideLoading,
	authenticated
} from 'redux/actions/Auth';
import localStorageHelper from 'helpers/localStorageHelper';
import Utils from "utils";
import AuthService from "services/AuthService";
import ShadowScrollbars from "components/shared-components/ShadowScrollbars";
import consoleBackendService from "services/ConsoleBackendService";

export const SSOChooseLoginSession = (props) => {
    const history = useHistory();
    const [searchTerm, setSearchTerm] = useState("");
    const [orgFilter, setOrgFilter] = useState([]);

    const {
        sessions = [],
        switchSession = false,
        currentSessionId = '',
        showLoading,
		hideLoading,
		loading,
		authenticated,
		redirect,
    } = props;

    const onLoginViaSSOSessionSelection = (session) => {
        showLoading();
        if (switchSession) {
            // We are already logged in and we want to switch session
            const ssoPotentialSessionReq = {
                entity: {
                    ssoPotentialSessionId: session.id,
                },
            };
            consoleBackendService
                .ssoSwitchSession(ssoPotentialSessionReq)
                .then((res) => {
                    const sessionFromServer = res.response.items[0];
                    localStorageHelper.onSignIn(sessionFromServer);
                    authenticated(sessionFromServer.token);
                    history.push(redirect);
                })
                .catch(function (err) {
                    hideLoading();
                    // TODO - handle error.
                    console.log("Error", err);
                });
        } else {
            AuthService.signInWithPotentialSession({ sessionId: session.id })
                .then((res) => {
                    const sessionFromServer = res.response.items[0];
                    localStorageHelper.onSignIn(sessionFromServer);
                    authenticated(sessionFromServer.token);
                    history.push(redirect);
                })
                .catch((err) => {
                    //TODO: handle error
                    hideLoading();
                });
        }
    };

        // get unique orgName from filtered sessions
        const uniqueOrgs = [];
        sessions.forEach((session) => {
            if (!uniqueOrgs.includes(session.orgName)) {
                uniqueOrgs.push(session.orgName);
            }
        });

    let filteredSessions = sessions;

    if (orgFilter.length > 0) {
        filteredSessions = filteredSessions.filter((session) =>
            orgFilter.includes(session.orgName)
        );
    }

    if (searchTerm.length > 0) {
        filteredSessions = Utils.searchArrayObjectsNestedValues(filteredSessions, searchTerm);
    }

    return (
        <Card bodyStyle={{paddingTop: "0px"}}>
            <Row gutter={24} className="my-3">
                <Col span={10}>
                    <Input
                        allowClear
                        value={searchTerm}
                        placeholder="Search"
                        prefix={<SearchOutlined />}
                        onChange={(e) => setSearchTerm(e.currentTarget.value)}
                    />
                </Col>
                <Col span={10}>
                    <Select
                        className="w-100"
                        showArrow
                        mode="multiple"
                        value={orgFilter}
                        onChange={(val) => {setOrgFilter(val)}}
                        placeholder="Organization"
                        filterOption={(input, option) => {
                            return (
                                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            );
                        }}>
                        {uniqueOrgs.map((elm) => (
                            <Select.Option key={elm} value={elm} name={elm}>
                                {elm}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
            </Row>
            <Row className="d-block" justify="center">
                <Col>
                    <ShadowScrollbars autoHide={false} autoHeight={true} autoHeightMax="50vh">
                        <Card bodyStyle={{overflowY: "hidden", paddingTop: "0px", paddingBottom: "0px"}}>
                            <List
                                loading={loading}
                                size="small"
                                itemLayout="horizontal"
                                dataSource={filteredSessions}
                                renderItem={(session) => (
                                    <List.Item>
                                        <Row>
                                            <Col span={24}>
                                                <span className="font-weight-semibold">Organization: </span> {session.orgName}
                                            </Col>
                                            <Col span={24}>
                                            <span className="font-weight-semibold">Role: </span>{session.displayOrgRole}
                                            </Col>
                                            {session.teams && <Col span={24}>
                                            <span className="font-weight-semibold pr-1">Teams: </span>{session.teams?.map((team) => (
                                                <Tag 
                                                    style={{borderRadius: '14px'}} 
                                                    key={team?.id || team?.name}>
                                                        {team?.name ? team?.name : team?.id}
                                                </Tag>))}
                                            </Col>}
                                        </Row>
                                        <Row>
                                        <Col>
                                            {currentSessionId === session.id ? (
                                                <Tag
                                                    color="blue"
                                                    style={{
                                                        fontSize: "14px",
                                                    }}>
                                                    Current Role
                                                </Tag>
                                            ) : (
                                                <Button
                                                    type="link"
                                                    onClick={() => onLoginViaSSOSessionSelection(session)}
                                                    style={{
                                                        minWidth: "115px", // Matches the Tag's width to keep alignment
                                                    }}>
                                                    Select
                                                </Button>
                                            )}
                                        </Col>
                                        </Row>
                                    </List.Item>
                                )}
                            />
                        </Card>
                    </ShadowScrollbars>
                </Col>
            </Row>
        </Card>
    );
};

const mapStateToProps = ({ auth }) => {
	const { loading, message, showMessage, token, redirect } = auth;
	return { loading, message, showMessage, token, redirect }
}

const mapDispatchToProps = {
	showLoading,
	hideLoading,
	authenticated
}

export default connect(mapStateToProps, mapDispatchToProps)(SSOChooseLoginSession);
