const backendValuesToDisplayValues = {
    'terraform': 'Terraform',
    'terragrunt': 'Terragrunt',
    'inSync': 'In Sync',
    'inSyncRefreshRequired': 'In Sync, Refresh Required',
    'inSyncDeployRequired': 'In Sync, Deploy Required',
    'drifted': 'Drifted',
    'notChecked': 'Not Checked',
    'finished': 'Finished',
    'inProgress': 'In Progress',
    'github.com': "Github.com",
    'gitlab.com': "Gitlab.com",
    'gitlab-onprem': 'Gitlab CE/EE',
    'bitbucket-cloud': 'Bitbucket Cloud',
    'azure-devops': 'Azure DevOps',
    'azure-devops-server': 'Azure DevOps Server',
    'github-enterprise': 'Github Enterprise Server',
    'github-enterprise-app': 'Github Enterprise Server Application',
    'terraformInit': 'Terraform Init',
    'terraformPlan': 'Terraform Plan',
    'terraformApply': 'Terraform Apply',
    'tfLint': 'TFLint',
    'tfSec': 'TFSec',
    'notStarted': 'Not Started',
    'succeeded': 'Success',
    'success': 'Success',
    'skipped': 'Skipped',
    'waitingForApproval': 'Waiting For Approval',
    'failed': 'Failed',
    'finishedError': 'Error',
    'approvalTimeout': 'Approval Timeout',
    'modified': 'Modified',
    'notEqual': 'Not Equal',
    'queued': 'Queued',
    'gitClone': 'Git Clone',
    'error': 'Error',
    'deploy-required': 'Deploy Required',
    'deployRequired': 'Deploy Required',
    'refreshRequired': 'Refresh Required',
    'deployRequiredDeletion': '(-) Deploy Required',
    'deployRequiredModification': '(~) Deploy Required',
    'deployRequiredCreation': '(+) Deploy Required',
    'deployRequiredModificationForcesReplacement': '-/+ Deploy Required',
    'modifiedForcesReplacement': 'Modified Forces Replacement',
    'unknown': 'Unknown',
    'ElasticLoadBalancingV2': 'ElbV2',
    'ElasticLoadBalancing': 'Elb',
    'managed': 'Managed',
    'unmanaged': 'Unmanaged',
    'moderate': 'Moderate',
    'minor': 'Minor',
    'major': 'Major',
    'critical': 'Critical',
    'issuesFound': 'Issues Found',
    'aws': 'AWS',
    'approved': 'Approved',
    'canceled': 'Canceled',
    'cancelling': 'Cancelling',
    'stack': 'Stack',
    'signIn': 'Sign In',
    'update': 'Update',
    'create': 'Create',
    'delete': 'Delete',
    'deleted': 'Deleted',
    'session': 'Session',
    'teamUser': 'Team Member',
    'team': 'Team',
    'admin': 'Admin',
    'member': 'Member',
    'runner': 'Runner',
    'nsOnly': 'Namespace Only',
    'tfVar': 'Terraform Variable',
    'envVar': 'Environment Variable',
    'organization': 'Organization',
    'blueprint': 'Blueprint',
    'namespace': 'Namespace',
    'customModel': 'Custom Model',
    'variable': 'Variable',
    'sshKey': 'SSH Key',
    'vcsCredentials': 'Vcs Credentials',
    'user': 'User',
    'viewer': 'Viewer',
    'deployer': 'Deployer',
    'namespacePermission': 'Namespace Permission',
    'template': 'Template',
    'cancelDeployment': 'Cancel Deployment',
    'approveDeployment': 'Approve Deployment',
    'templateNamespaceMapping': 'Template Namespace Association',
    'notRelevant': 'Not Relevant',
    'pendingCalculation': 'Pending Calculation',
    'calculating': 'Calculating',
    'suggestedStackReady': 'Ready To Import',
    'suggestedStackCodeReady': 'Code Ready',
    'suggestedStackImported': 'Imported',
    'slack': 'Slack',
    'slackApp': 'Slack App',
    'teams': 'Teams',
    'stack::driftDetected': 'Stack - Drift Detected',
    'stack::deployment::waitingForApproval': 'Stack - Deployment waiting for approval',
    'stack::deployment::waitingForFailedControlPoliciesApproval': 'Stack - Deployment waiting for failed control policies approval',
    'stack::deployment::failed': 'Stack - Deployment failed',
    'stack::deployment::approvalTimeout': 'Stack - Deployment reached approval timeout',
    'stack::deployment::applyStarted': 'Stack - Deployment - Apply started',
    'stack::deployment::applyFinished': 'Stack - Deployment - Apply finished',
    'stack::deployment::applyFailure': 'Stack - Deployment - Apply failure',
    'awsCredentials': 'AWS Credentials',
    'controlPoliciesChecks': 'Control Policies',
    'warning': 'Warning',
    'softMandatory': 'Soft Mandatory',
    'hardMandatory': 'Hard Mandatory',
    'validateControlPolicies': 'Validate Control Policies',
    'waitingForFailedControlPoliciesApproval': 'Waiting for Failed Policies Approval',
    'waitingForErrorControlPoliciesApproval': 'Waiting for Error Policies Approval',
    'approvedFailedControlPolicies': 'Approved Failed Policies',
    'approveDeploymentFailedPolicies': 'Approved Failed Policies',
    'failedControlPolicies': 'Control Policies Didn\'t pass',
    'infracost': 'Infracost',
    'plan': 'Plan',
    'controlPolicy': 'Control Policy',
    'controlPolicyNamespaceMapping': 'Control Policy - Namespace Mappping',
    'controlPolicyMapping' : 'Control Policy Mapping',
    'deployment': 'Deployment',
    'gitCloneResult': 'Git Clone',
    'terraformInitResult': 'Terraform Init',
    'tfLintResult': 'TFLint',
    'tfSecResult': 'TFSec',
    'terraformPlanResult': 'Terraform Plan',
    'infracostResult': 'Infracost',
    'controlPoliciesResult': 'Control Policies',
    'terraformApplyResult': 'Terraform Apply',
    'stackResourcesImport': 'Stack Resources Import',
    'approveResourcesImport': 'Approve Resources Import',
    'programmaticUser': 'Programmatic User',
    'programmaticToken': 'Programmatic Token',
    'stackInitializationRetry': 'Stack Initialization Retry',
    'gcpCredentials': 'GCP Credentials',
    'azureCredentials': 'Azure Credentials',
    'createFromTemplate': 'Create From Template',
    'cancelResourcesImport': 'Cancel Resources Import',
    'stackResources': 'Stack Resources',
    'applyFailure': 'Apply Failure',
    'orgConfiguration': 'Configuration',
    'createFromBlueprint': 'Create From Blueprint',
    'blueprintNamespaceMapping': 'Blueprint Namespace Mapping',
    'identityProvider': 'Identity Provider',
    'subscription': 'Subscription',
    'variableGroup': 'Variable Group',
    'customRole': 'Custom Role',
    'approve': 'Approve',
    'approveFailedPolicies': 'Approve Failed Policies',
    'cancel': 'Cancel',
    'driftDetection': 'Drift Detection',
    'driftRemediation': 'Drift Remediation',
    'pendingInvite': 'Pending Invite',
    'notificationEndpoint': 'Notification Endpoint',
    'eventRule': 'Event Rule',
    'low': 'Low',
    'medium': 'Medium',
    'high': 'High',
    'controlPolicyGroupMapping': 'Control Policy Group Mapping',
    'BitBucketDataCenter': 'Bitbucket Data Center',
    'bitbucket-data-center': 'Bitbucket Data Center',
    'taskExecutionResult': 'Task Execution Result',
    'task': 'Task',
    'customAbacConfiguration': 'Custom ABAC Configuration',
    'extendTtl': 'Stack extend TTL',
    'stackDependency': 'Stack Dependency',
    'canceledByDependency': 'Canceled By Dependency',
    'awsAutoManagedStacksConfiguration': 'AWS Auto Managed Stacks Configuration',
    'stackDiscoveryConfiguration': 'Stack Auto Discovery',
    'aws::consoleOperation': 'AWS Console Operation',
    'disasterRecoveryConfiguration': 'Disaster Recovery Configuration',
    'stackCreated': 'Stack Created',
    'orgSigninConfiguration': 'Sign In Cofiguration'
}

export default function MapBackendValueToDisplayValue(backendValue) {
    var retVal = backendValuesToDisplayValues[backendValue];
    if (retVal === undefined) {
        retVal = backendValue;
    }

    return retVal;
}