import fetch from 'auth/FetchInterceptor'

const AuthService = {}

AuthService.login = function (data) {

	let signInRequest ={
		entity: {
			'password': data.password,
			'email': data.email
		}
	};

	return fetch({
		url: '/auth/signIn',
		method: 'post',
		headers: {
      'public-request': 'true'
    },
		data: signInRequest
	})
}

AuthService.signUp = function (data) {
	return fetch({
		url: '/auth/signup',
		method: 'post',
		headers: {
      'public-request': 'true'
    },
		data: data
	})
}

AuthService.signupOrganization = function (data) {
    const signUpRequest = {
        entity: {
            ...data,
        },
    };

    return fetch({
        url: "/auth/signUp",
        method: "post",
        headers: {
            "public-request": "true",
        },
        data: signUpRequest,
    });
};

AuthService.awsMarketplaceRegistration = function (data) {

	return fetch({
		url: '/auth/awsMarketplace/registration',
		method: 'post',
		headers: {
      'public-request': 'true'
    },
		data: data
	})
}

AuthService.createOrgUserFromToken = function (data) {
    const userAcceptInviteRequest = {
        entity: {
            ...data,
        },
    };

    return fetch({
        url: "/iam/org/user/v2",
        method: "post",
        headers: {
            "public-request": "true",
        },
        data: userAcceptInviteRequest,
    });
};

AuthService.addExistingUserToOrgToken = function (data) {
    const existingUserAcceptInviteRequest = {
        entity: {
            ...data,
        },
    };

    return fetch({
        url: "/iam/org/user/acceptInvite",
        method: "post",
        headers: {
            "public-request": "true",
        },
        data: existingUserAcceptInviteRequest,
    });
};

AuthService.userRecoverPassword = function (data) {
    const forgotPasswordRequest = {
        entity: {
            ...data,
        },
    };

    return fetch({
        url: "/iam/org/user/recoverPassword",
        method: "post",
        headers: {
            "public-request": "true",
        },
        data: forgotPasswordRequest,
    });
};

AuthService.userResetPassword = function (data) {
    const resetPasswordRequest = {
        entity: {
            ...data,
        },
    };

    return fetch({
        url: "/iam/org/user/resetPassword",
        method: "post",
        headers: {
            "public-request": "true",
        },
        data: resetPasswordRequest,
    });
};

AuthService.ssoLoginRequest = function (data) {
    const ssoLoginRequest = {
        entity: {
            ...data,
        },
    };

    return fetch({
        url: "/auth/sso/signInRequest",
        method: "post",
        headers: {
            "public-request": "true",
        },
        data: ssoLoginRequest
    });
};

AuthService.signInWithPotentialSession = function (data) {
    const potentialSessionSignInRequest ={
        entity: {
            ssoPotentialSessionId: data.sessionId
        }
    };

    return fetch({
        url: '/auth/sso/signInWithPotentialSession',
        method: 'post',
        headers: {
            'public-request': 'true'
        },
        data: potentialSessionSignInRequest
    })
}

AuthService.signInWithMFAPotentialSession = function (data) {
    const potentialSessionSignInRequest ={
        entity: {
            waitingMfaPotentialSessionId: data.id,
            mfaCode: data.mfaCode
        }
    };

    return fetch({
        url: '/auth/signInWithMfaPotentialSession',
        method: 'post',
        headers: {
            'public-request': 'true'
        },
        data: potentialSessionSignInRequest
    })
}

AuthService.mfaConfirm = function (data) {
    return fetch({
        url: 'iam/org/user/mfa/confirm',
        method: 'post',
        headers: {
            'public-request': 'true'
        },
        data
    });
}

AuthService.mfaGenerateWithAddMFASession = function (data) {
    return fetch({
        url: 'iam/org/user/mfa/generate/withAddMfaSession',
        method: 'post',
        headers: {
            'public-request': 'true'
        },
        data
    });
}

AuthService.mfaConfigureWithAddMFASession = function (data) {
    return fetch({
        url: 'iam/org/user/mfa/configure/withAddMfaSession',
        method: 'post',
        headers: {
            'public-request': 'true'
        },
        data
    });
}

export default AuthService