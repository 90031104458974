import React, { useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import css from "dom-css";

function ShadowScrollbars(props) {
    const [scrollState, setScrollState] = useState({
        scrollTop: 0,
        scrollHeight: 0,
        clientHeight: 0,
    });

    const scrollbarsRef = useRef(null);
    const shadowTopRef = useRef(null);
    const shadowBottomRef = useRef(null);

    const handleUpdate = (values) => {
        const { scrollTop, scrollHeight, clientHeight } = values;
        const shadowTopOpacity = (1 / 20) * Math.min(scrollTop, 20);
        const bottomScrollTop = scrollHeight - clientHeight;
        const shadowBottomOpacity = (1 / 20) * (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - 20));

        css(shadowTopRef.current, { opacity: shadowTopOpacity });
        css(shadowBottomRef.current, { opacity: shadowBottomOpacity });

        setScrollState({
            scrollTop,
            scrollHeight,
            clientHeight,
        });
    };

    const containerStyle = {
        ...props.style,
        position: "relative",
    };

    const shadowTopStyle = {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        height: 10,
        background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%)",
    };

    const shadowBottomStyle = {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        height: 10,
        background: "linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%)",
    };

    useEffect(() => {
        const scrollbars = scrollbarsRef.current;
        if (scrollbars) {
            scrollbars.scrollToTop();
        }
    }, []);

    return (
        <div style={containerStyle}>
            <Scrollbars ref={scrollbarsRef} onUpdate={handleUpdate} {...props} />
            <div ref={shadowTopRef} style={shadowTopStyle} />
            <div ref={shadowBottomRef} style={shadowBottomStyle} />
        </div>
    );
}

export default ShadowScrollbars;
