import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Modal } from "antd";
import { LIGHT_MODE } from "constants/ThemeConstant";
import SSOChooseLoginSession from "pages/auth-pages/components/SSOChooseLoginSession";
import MapBackendValueToDisplayValue from "lang/mappings";

const SsoLoginChooseSessionModal = forwardRef((props, ref) => {
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({});
    let { sessions, currentTheme, currentSessionId } = modalData;

    const handleOpenModal = (data = {}) => {
        setShowModal(true);
        setModalData(data);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    // apply forwardRef child function to open the modal
    useImperativeHandle(ref, () => ({
        handleOpenModal,
    }));

    if (Array.isArray(sessions) && sessions.length > 1) {
        sessions.sort((a, b) => a?.orgName.localeCompare(b?.orgName));
        sessions = sessions.map((session) => ({
            ...session,
            displayOrgRole: MapBackendValueToDisplayValue(session.orgRole),
        }))
    };

    return (
        <Modal
            visible={showModal}
            onCancel={handleCloseModal}
            footer={null}
            width={800} // Set a specific width or use "auto"
            bodyStyle={{ overflowX: "auto" }} // Ensures horizontal scrolling if needed
            style={{ minWidth: "700px", maxWidth: "90vw" }}>
            <div className="my-2">
                <div className="text-center">
                    <img
                        className="img-fluid"
                        style={{ width: 200, height: 100 }}
                        src={`/img/${currentTheme === LIGHT_MODE ? "cm-logo.svg" : "cm-logo-white.svg"}`}
                        alt=""
                    />
                </div>
                <div className="text-center">
                    <h3 className="mt-3">Choose one of the following roles:</h3>
                </div>
                <SSOChooseLoginSession sessions={sessions} switchSession={true} currentSessionId={currentSessionId}/>
            </div>
        </Modal>
    );
});

export default SsoLoginChooseSessionModal;
