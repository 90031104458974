import React, { useState, useRef, useEffect } from "react";
import { Menu, Dropdown, Badge, Row, Col, List } from "antd";
import localStorageHelper from "helpers/localStorageHelper";
import moment from "moment";
import { LIGHT_MODE } from "constants/ThemeConstant";

const getNotificationBody = (list, latestNotificationViewDate, currentTheme) => {
    if (list.length === 0) {
        return (
            <div className="empty-notification">
                <p className="mt-3">No notifications yet</p>
            </div>
        );
    }

    const newItemBGColor = currentTheme === LIGHT_MODE ? 'rgb(240 249 255)' : 'black';

    return (
        <List
            size="small"
            itemLayout="horizontal"
            dataSource={list}
            renderItem={(item) => (
                <List.Item className="p-0">
                    <Row style={{ backgroundColor: item.isNew ? newItemBGColor : "inherit" }} className="px-2 py-1">
                        <Col span={24} className="font-weight-semibold mt-1">{item.title}</Col>
                        <Col>
                        <span className="text-gray-light">
                                {item.text.split('\n').map((line, idx) => {
                                    if (line.includes("[LINK]") && item.linkText && item.linkUrl) {
                                        const parts = line.split("[LINK]");
                                        return (
                                            <span key={idx}>
                                                {parts[0]}
                                                <a href={item.linkUrl} target="_blank">
                                                    {item.linkText}
                                                </a>
                                                {parts[1]}
                                                <br />
                                            </span>
                                        );
                                    }
                                    return (<span key={idx}>{line}<br /></span>);
                                })}
                            </span>
                        </Col>
                        <Col span={8} offset={16}>
                            <small style={{ color: "#108ee9" }}>{moment(item.date).format("Do MMM YYYY")}</small>
                        </Col>
                    </Row>
                </List.Item>
            )}
        />
    );
};

export const NavNotification = ({currentTheme}) => {
    const [latestNotificationDate, setLatestNotificationDate] = useState();
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState([]);
    const containerRef = useRef(null);
    const containerOpenCountRef = useRef(0);

    const loadNotificationsData = () => {
        fetch("/data/notifications.json")
            .then((response) => response.json())
            .then((res) => {
                const data = res || [];
                setData(data);
            })
            .catch((err) => {
                console.log("Error: could not fetch notifications", err);
            });
    };

    const handleVisibleChange = (flag) => {
        setVisible(flag);
    };

    useEffect(() => {
        const lvd = localStorageHelper.getLatestNotificationDate();
        if (lvd) {
            setLatestNotificationDate(moment(lvd));
        }

        // check new messages
        loadNotificationsData();

        // set interval - for now commented
        // let notificationsDataInervalId;
        // setInterval(() => loadNotificationsData(), 5000);
        // return () => {
        //     if (notificationsDataInervalId) {
        //         clearInterval(notificationsDataInervalId);
        //     }
        // };
    }, []);

    useEffect(() => {
        if (visible && containerRef.current) {
            const windowHeight = window.innerHeight;
            containerOpenCountRef.current += 1;
            // Get the first 5 children of the container
            const firstFiveLiElements = Array.from(containerRef.current.querySelectorAll("li")).slice(0, 5);

            // Get the height of each child
            let totalHeight = 0;
            firstFiveLiElements.forEach((liElement) => {
                const childHeight = liElement.offsetHeight;
                // we don't want to overflow the window height so we take the max height before overflowing window height
                if ((totalHeight + childHeight) < windowHeight) {
                    totalHeight += childHeight;
                }
            });

            if (totalHeight > 0) {
                // set the height by the children heights (even if less then 5 children)
                containerRef.current.style.maxHeight = `${totalHeight}px`;
            }
        } else if (!visible) {
            if (containerOpenCountRef.current > 0) {
                let latestDate = list?.[0]?.date;
                if (latestDate) {
                    latestDate = moment(latestDate).utc().format();
                    setLatestNotificationDate(latestDate);
                    localStorageHelper.setLatestNotificationDate(latestDate);
                }
            }
        }
    }, [visible]);

    let newCount = 0;
    const list = data
        .map((notification) => {
            const { date } = notification;
            // only if we have a saved localstorage date
            const isNew = latestNotificationDate ? moment(latestNotificationDate).isBefore(date) : true;
            if (isNew) {
                newCount += 1;
            }

            return {
                ...notification,
                isNew,
            };
        })
        .sort((a, b) => moment(b.date).unix() - moment(a.date).unix());

    const notificationList = (
        <div className="nav-dropdown nav-notification">
            <div className="nav-notification-header d-flex justify-content-between align-items-center">
                <h4 className="mb-0">What's new</h4>
            </div>
            <div className="nav-notification-body" ref={containerRef}>
                {getNotificationBody(list, latestNotificationDate, currentTheme)}
            </div>
        </div>
    );

    return (
        <Dropdown
            placement="bottomRight"
            overlay={notificationList}
            onVisibleChange={handleVisibleChange}
            visible={visible}
            trigger={["click"]}>
            <Menu mode="horizontal" className="mx-2">
                <Menu.Item key="notification">
                    <Badge count={newCount > 5 ? '5+' : newCount} offset={[0, 24]} color="yellow">
                        <div className="mr-2 font-weight-normal">
                            What's New
                        </div>
                    </Badge>
                </Menu.Item>
            </Menu>
        </Dropdown>
    );
};

export default NavNotification;
