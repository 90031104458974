import React, { useState, useEffect, useRef } from "react";
import { SwapOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Form, Modal, Select, Row, Col, message } from "antd";
import localStorageHelper from "helpers/localStorageHelper";
import consoleBackendService from "services/ConsoleBackendService";
import SsoLoginChooseSessionModal from "pages/auth-pages/authentication/ssoLoginChooseSessionModal";
import { ORGANIZATION } from "redux/constants/Auth";
import utils_lodash from 'lodash';
import { LIGHT_MODE } from "constants/ThemeConstant";

export const NavOrgSelector = ({currentTheme}) => {

    const [isLoading, setIsLoading] = useState(true);
    const [allowedOrgs, setAllowedOrgs] = useState([]);
    const [ssoPotentialSessions, setSsoPotentialSessions] = useState([]);
    const [currentSessionId, setCurrentSessionId] = useState('');
    const [currOrg, setCurrOrg] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const openSwitchSessionModalRef = useRef(null);

    const SwitchOrgModal = ({ visible, onCreate, onCancel }) => {
        const [form] = Form.useForm();
        return (
            <Modal
                title="Switch Organization"
                visible={visible}
                okText="Switch"
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then(values => {
                            onCreate(values);
                        })
                        .catch(info => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    name="switchOrgForm"
                    layout="vertical"
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="organization" label="Organization"
                                rules={[{ required: true, message: 'Please choose organization' }]}>
                                <Select className="w-100" showSearch
                                    filterOption={(input, option) => option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {
                                        allowedOrgs.map(elm => (
                                            <Select.Option key={elm.id} value={elm.id} name={elm.name}>{elm.name}</Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }

    const showModal = () => {
        setModalVisible(true);
    }

    const handleOk = (values) => {

        if (values.organization !== currOrg.id) {
            consoleBackendService.tradeSession({ desiredOrganizationId: values.organization }).then(res => {
                let newSession = res.response.items[0];
                console.log(newSession)
                message.success("Switching to " + newSession.organization.name);
                setModalVisible(false);

                localStorageHelper.onOrgChange(newSession);

                // Reload the window
                window.location.href = "/";

                // loadData();
            }).catch(function (err) {
                // TODO - handle error.
                console.log("Error", err);
                message.error("Failed to switch organization");
                setModalVisible(false);
            });
        } else { // Same org - nothing to do
            setModalVisible(false);
        }
    }

    const handleCancel = () => {
        setModalVisible(false);
    }


    useEffect(() => {
        let isCancelled = false;
        const organizationStr = localStorage.getItem(ORGANIZATION);
        let organization;
        if (organizationStr) {
            organization = JSON.parse(organizationStr);
            setCurrOrg(organization);
        }

        setIsLoading(true);

        Promise.all([
            consoleBackendService.getCurrUserAllowedOrgs(),
            consoleBackendService.getSsoPotentialSession()
        ])
            .then(([orgsRes, ssoRes]) => {
                // Process allowed organizations
                let orgs = orgsRes.response.items;
                let sortedOrgs = utils_lodash.sortBy(orgs, 'name');
                setAllowedOrgs(sortedOrgs);
        
                // Process SSO potential sessions
                const ssoPotentialSessions = ssoRes.response.items[0].ssoPotentialSessions
                const currentSessionId = ssoRes.response.items[0].currentSessionPotentialSessionId
                setSsoPotentialSessions(ssoPotentialSessions);
                setCurrentSessionId(currentSessionId);
            })
            .catch(function (err) {
                // TODO - handle error
                console.log("Error", err);
            })
            .finally(() => {
                setIsLoading(false);
            });

        return () => {
            isCancelled = true;
        };

    }, []);

    const onSwitchOrg = (elm) => {
        showModal();
    }

    const handleOpenSwitchSessionModalRef = () => {
        if (openSwitchSessionModalRef.current) {
            openSwitchSessionModalRef.current.handleOpenModal({
                sessions: ssoPotentialSessions,
                currentTheme,
                currentSessionId
            });
        }
    };

    const textColorClassName = currentTheme === LIGHT_MODE ? 'text-gray' : 'text-white';

    const orgOptions = (
        <Menu>
            <Menu.Item
                key='Switch Organizations'
                className='ant-dropdown-menu-item-active'
                onClick={() => onSwitchOrg()}
            >
                <span className="d-flex justify-content-between align-items-center">
                    <div>
                        <SwapOutlined />
                        <span className="font-weight-normal ml-2">Switch Organization</span>
                    </div>
                </span>
            </Menu.Item>
        </Menu>
    )

    if (isLoading) {
        return (<div />)
    }

    if (allowedOrgs.length > 1) {
        // Display the option to switch between organizations
        return (
            <div style={{marginTop: '2px'}}>
                <SwitchOrgModal visible={modalVisible} onCreate={handleOk} onCancel={handleCancel} />
                <Dropdown placement="bottomRight" overlay={orgOptions} trigger={["click"]}>
                    {
                        <a href="#/" className={textColorClassName} style={{whiteSpace: 'nowrap'}} onClick={e => e.preventDefault()}>
                            {currOrg.name}
                        </a>
                    }
                </Dropdown>
            </div>
        )
    } else if (ssoPotentialSessions.length > 1) {
        // Display the option to switch between organizations
        return (
            <div style={{marginTop: '2px'}}>
                <SsoLoginChooseSessionModal ref={openSwitchSessionModalRef}/>
                    <a href="#/" className={textColorClassName} style={{whiteSpace: 'nowrap'}} onClick={() => handleOpenSwitchSessionModalRef()}>
                        {currOrg.name}
                    </a>
            </div>
        )
    } else {
        // Just 1 org
        return <div className={textColorClassName} style={{marginTop: '2px', whiteSpace: 'nowrap'}}>{currOrg.name}</div>
    }

}


export default NavOrgSelector;