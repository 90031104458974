import fetch from 'auth/FetchInterceptor'

const consoleBackendService = {}


consoleBackendService.createGitbookToken = function (requestParams) {
  return fetch({
    url: '/docs/gitbookToken',
    method: 'post',
  })
}

////////////////////
// Aws Creds
////////////////////
consoleBackendService.getAwsCreds = function (requestParams) {
  return fetch({
    url: '/aws/credentials',
    method: 'get',
    params: { type: requestParams?.type }
  })
}

consoleBackendService.createAwsCreds = function (data) {
  return fetch({
    url: '/aws/credentials',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateAwsCreds = function (requestParams) {
  return fetch({
    url: `/aws/credentials/${requestParams.credentialsId}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.deleteAwsCreds = function (requestParams) {
  return fetch({
    url: `/aws/credentials/${requestParams.credentialsId}`,
    method: 'delete',
  })
}

consoleBackendService.validateAwsCreds = function (data) {
  return fetch({
    url: '/aws/credentials/validateConnection',
    method: 'post',
    data: data
  })
}

consoleBackendService.collectAwsAccountResources = function (requestParams) {
  return fetch({
    url: '/aws/resource/collect',
    method: 'post',
    params: requestParams
  })
}

consoleBackendService.validateAzureCreds = function (data) {
  return fetch({
    url: '/azure/credentials/validateConnection',
    method: 'post',
    data: data
  })
}

consoleBackendService.validateGcpCreds = function (data) {
  return fetch({
    url: '/gcp/credentials/validateConnection',
    method: 'post',
    data: data
  })
}

consoleBackendService.getAwsCredsStackSets = function (requestParams) {
  return fetch({
    url: '/aws/credentials/stackSet',
    method: 'get',
    params: { extendedOutput: requestParams?.extendedOutput }
  })
}

consoleBackendService.connectAwsCredsStackSets = function (data) {
  return fetch({
    url: '/aws/credentials/stackSet/connectCredentials',
    method: 'post',
    data: data
  })
}

consoleBackendService.deleteAwsCredsStackSet = function (requestParams) {
  return fetch({
    url: `/aws/credentials/stackSet/${requestParams.credentialsStackSetId}`,
    method: 'delete',
  })
}

consoleBackendService.getAwsCredsStackSetsMetaData = function (requestParams) {
  return fetch({
    url: '/aws/stackSet/metadata',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.createAwsCredsStackSet = function (data) {
  return fetch({
    url: '/aws/credentials/stackSet',
    method: 'post',
    data: data
  })
}

consoleBackendService.validateAwsStackSet = function (requestParams) {
  return fetch({
    url: '/aws/stackSet/validate',
    method: 'post',
    params: requestParams
  })
}

consoleBackendService.validateAwsStackSet = function (requestParams) {
  return fetch({
    url: '/aws/stackSet/validate',
    method: 'post',
    params: requestParams
  })
}

////////////////////
// Gcp Creds
////////////////////
consoleBackendService.getGcpCreds = function (requestParams) {
  return fetch({
    url: '/gcp/credentials',
    method: 'get',
    params: { type: requestParams?.type }
  })
}

consoleBackendService.createGcpCreds = function (data) {
  return fetch({
    url: '/gcp/credentials',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateGcpCreds = function (requestParams) {
  return fetch({
    url: `/gcp/credentials/${requestParams.credentialsId}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.deleteGcpCreds = function (requestParams) {
  return fetch({
    url: `/gcp/credentials/${requestParams.credentialsId}`,
    method: 'delete',
  })
}

////////////////////
// Azure Creds
////////////////////
consoleBackendService.getAzureCreds = function (requestParams) {
  return fetch({
    url: '/azure/credentials',
    method: 'get',
    params: { type: requestParams?.type }
  })
}

consoleBackendService.createAzureCreds = function (data) {
  return fetch({
    url: '/azure/credentials',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateAzureCreds = function (requestParams) {
  return fetch({
    url: `/azure/credentials/${requestParams.credentialsId}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.deleteAzureCreds = function (requestParams) {
  return fetch({
    url: `/azure/credentials/${requestParams.credentialsId}`,
    method: 'delete',
  })
}

////////////////////
// AWS Account Stats
////////////////////
consoleBackendService.resourceCountByResourceType = function (requestParams) {
  return fetch({
    url: '/aws/accountStats/resourceCountByResourceType',
    method: 'get',
    params: { awsAccountId: requestParams.awsAccountId }
  })
}

consoleBackendService.getResourceCountByService = function (requestParams) {
  return fetch({
    url: '/aws/accountStats/resourceCountByService',
    method: 'get'
  })
}

consoleBackendService.getResourceCountByRegion = function (requestParams) {
  return fetch({
    url: '/aws/accountStats/resourceCount',
    method: 'get'
  })
}

consoleBackendService.getResourceCountByRegionDaily = function (requestParams) {
  return fetch({
    url: '/aws/accountStats/resourceCountDaily',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getResourceCountDailyAggregated = function (requestParams) {
  return fetch({
    url: '/aws/accountStats/resourceCountDailyAggregated',
    method: 'get',
    params: requestParams
  })
}

////////////////////
// Azure Account Stats
////////////////////
consoleBackendService.azureResourceCountByResourceType = function (requestParams) {
  return fetch({
    url: '/azure/subscriptionStats/resourceCountByResourceType',
    method: 'get',
    params: { azureSubscriptionId: requestParams.azureSubscriptionId }
  })
}

consoleBackendService.azureGetResourceCountDailyAggregated = function (requestParams) {
  return fetch({
    url: '/azure/subscriptionStats/resourceCountDailyAggregated',
    method: 'get',
    params: requestParams
  })
}

////////////////////
// GCP Account Stats
////////////////////
consoleBackendService.gcpResourceCountByResourceType = function (requestParams) {
  return fetch({
    url: '/gcp/projectStats/resourceCountByResourceType',
    method: 'get',
    params: { gcpProjectId: requestParams.gcpProjectId }
  })
}

consoleBackendService.gcpGetResourceCountDailyAggregated = function (requestParams) {
  return fetch({
    url: '/gcp/projectStats/resourceCountDailyAggregated',
    method: 'get',
    params: requestParams
  })
}

////////////////////
// AWS Suggested Stacks
////////////////////
consoleBackendService.getSuggestedStacks = function (requestParams) {
  return fetch({
    url: '/aws/suggestedStack',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getSuggestedStackResources = function (requestParams) {
  return fetch({
    url: '/aws/suggestedStackResource',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getSuggestedStackTemplateFiles = function (requestParams) {
  return fetch({
    url: '/aws/suggestedStackTemplateFiles',
    method: 'get',
    params: { suggestedStackId: requestParams.suggestedStackId }
  })
}

consoleBackendService.findSuggestedStack = function (data) {
  return fetch({
    url: '/aws/findSuggestedStack',
    method: 'post',
    data: data
  })
}

consoleBackendService.suggestedStackImportPullRequest = function (data) {
  return fetch({
    url: '/aws/suggestedStackImportPullRequest',
    method: 'post',
    data: data
  })
}

consoleBackendService.suggestedStackImportPullRequestBulk = function (data) {
  return fetch({
    url: '/aws/suggestedStackImportPullRequestBulk',
    method: 'post',
    data: data
  })
}

consoleBackendService.suggestedStackImportPullRequestStatus = function (requestParams) {
  return fetch({
    url: '/aws/suggestedStackImportPullRequestStatus',
    method: 'get',
    params: {suggestedStackImportPullRequestId: requestParams.suggestedStackImportPullRequestId}
  })
}

////////////////////
// Azure Suggested Stacks
////////////////////
consoleBackendService.azureGetSuggestedStacks = function (requestParams) {
  return fetch({
    url: '/azure/suggestedStack',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.azureGetSuggestedStackResources = function (requestParams) {
  return fetch({
    url: '/azure/suggestedStackResource',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.azureGetSuggestedStackTemplateFiles = function (requestParams) {
  return fetch({
    url: '/azure/suggestedStackTemplateFiles',
    method: 'get',
    params: { suggestedStackId: requestParams.suggestedStackId }
  })
}

consoleBackendService.azureFindSuggestedStack = function (data) {
  return fetch({
    url: '/azure/findSuggestedStack',
    method: 'post',
    data
  })
}

consoleBackendService.azureSuggestedStackImportPullRequest = function (data) {
  return fetch({
    url: '/azure/suggestedStackImportPullRequest',
    method: 'post',
    data: data
  })
}

consoleBackendService.azureSuggestedStackImportPullRequestBulk = function (data) {
  return fetch({
    url: '/azure/suggestedStackImportPullRequestBulk',
    method: 'post',
    data: data
  })
}

consoleBackendService.azureSuggestedStackImportPullRequestStatus = function (requestParams) {
  return fetch({
    url: '/azure/suggestedStackImportPullRequestStatus',
    method: 'get',
    params: {suggestedStackImportPullRequestId: requestParams.suggestedStackImportPullRequestId}
  })
}

////////////////////
// GCP Suggested Stacks
////////////////////
consoleBackendService.gcpGetSuggestedStacks = function (requestParams) {
  return fetch({
    url: '/gcp/suggestedStack',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.gcpGetSuggestedStackResources = function (requestParams) {
  return fetch({
    url: '/gcp/suggestedStackResource',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.gcpGetSuggestedStackTemplateFiles = function (requestParams) {
  return fetch({
    url: '/gcp/suggestedStackTemplateFiles',
    method: 'get',
    params: { suggestedStackId: requestParams.suggestedStackId }
  })
}

consoleBackendService.gcpFindSuggestedStack = function (data) {
  return fetch({
    url: '/gcp/findSuggestedStack',
    method: 'post',
    data: data
  })
}

consoleBackendService.gcpSuggestedStackImportPullRequest = function (data) {
  return fetch({
    url: '/gcp/suggestedStackImportPullRequest',
    method: 'post',
    data: data
  })
}

consoleBackendService.gcpSuggestedStackImportPullRequestBulk = function (data) {
  return fetch({
    url: '/gcp/suggestedStackImportPullRequestBulk',
    method: 'post',
    data: data
  })
}

consoleBackendService.gcpSuggestedStackImportPullRequestStatus = function (requestParams) {
  return fetch({
    url: '/gcp/suggestedStackImportPullRequestStatus',
    method: 'get',
    params: {suggestedStackImportPullRequestId: requestParams.suggestedStackImportPullRequestId}
  })
}

////////////////////
// Namespaces
////////////////////
consoleBackendService.getNamespaces = function (requestParams) {
  return fetch({
    url: '/namespace',
    method: 'get'
  })
}

consoleBackendService.getNamespace = function (requestParams) {
  return fetch({
    url: `/namespace/${requestParams.namespaceId}`,
    method: 'get'
  })
}

consoleBackendService.createNamespace = function (data) {
  return fetch({
    url: '/namespace',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateNamespace = function (requestParams) {
  return fetch({
    url: `/namespace/${requestParams.namespaceId}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.deleteNamespace = function (requestParams) {
  return fetch({
    url: `/namespace/${requestParams.namespaceId}`,
    method: 'delete',
  })
}

////////////////////
// Templates
////////////////////
consoleBackendService.createTemplate = function (data) {
  return fetch({
    url: '/template',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateTemplate = function (requestParams) {
  return fetch({
    url: `/template/${requestParams.templateId}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.getTemplates = function (requestParams) {
  return fetch({
    url: '/template',
    method: 'get',
    params: { namespaceId: requestParams?.namespaceId }
  })
}

consoleBackendService.getTemplate = function (requestParams) {
  return fetch({
    url: `/template/${requestParams.templateId}`,
    method: 'get'
  })
}

consoleBackendService.deleteTemplate = function (requestParams) {
  return fetch({
    url: `/template/${requestParams.templateId}`,
    method: 'delete',
  })
}

consoleBackendService.getNamespaceTemplateAssocications = function (requestParams) {
  return fetch({
    url: '/template/templateNamespaceMapping',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.associateNamespaceToTemplate = function (data) {
  return fetch({
    url: '/template/templateNamespaceMapping',
    method: 'post',
    data: data
  })
}

consoleBackendService.disassociateNamespaceFromTemplate = function (data) {
  return fetch({
    url: '/template/templateNamespaceMapping',
    method: 'delete',
    data: data
  })
}

consoleBackendService.getTemplateEvents = function () {
  return fetch({
    url: '/template/event',
    method: 'get',
  })
}

////////////////////
// Blueprints
////////////////////
consoleBackendService.createBlueprint = function (data) {
  return fetch({
    url: '/blueprint',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateBlueprint = function (requestParams) {
  return fetch({
    url: `/blueprint/${requestParams.blueprintId}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.getBlueprints = function (requestParams) {
  return fetch({
    url: '/blueprint',
    method: 'get',
    params: { namespaceId: requestParams?.namespaceId }
  })
}

consoleBackendService.getBlueprint = function (requestParams) {
  return fetch({
    url: `/blueprint/${requestParams.blueprintId}`,
    method: 'get'
  })
}

consoleBackendService.deleteBlueprint = function (requestParams) {
  return fetch({
    url: `/blueprint/${requestParams.blueprintId}`,
    method: 'delete',
  })
}

consoleBackendService.getNamespaceBlueprintAssocications = function (requestParams) {
  return fetch({
    url: '/blueprint/blueprintNamespaceMapping',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.associateNamespaceToBlueprint = function (data) {
  return fetch({
    url: '/blueprint/blueprintNamespaceMapping',
    method: 'post',
    data: data
  })
}

consoleBackendService.disassociateNamespaceFromBlueprint = function (data) {
  return fetch({
    url: '/blueprint/blueprintNamespaceMapping',
    method: 'delete',
    data: data
  })
}

////////////////////
// Stacks
////////////////////
consoleBackendService.createStack = function (data) {
  return fetch({
    url: '/stack',
    method: 'post',
    data: data
  })
}

consoleBackendService.createStackFromTemplate = function (data) {
  return fetch({
    url: 'stack/fromTemplate',
    method: 'post',
    data: data
  })
}

consoleBackendService.createStackFromBlueprint = function (data) {
  return fetch({
    url: 'stack/fromBlueprint',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateStack = function (requestParams) {
  return fetch({
    url: `/stack/${requestParams.stackId}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.getStacks = function (requestParams) {
  return fetch({
    url: '/stack',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getStack = function (requestParams) {
  return fetch({
    url: `/stack/${requestParams.stackId}`,
    method: 'get'
  })
}

consoleBackendService.getStackResources = function (requestParams) {
  return fetch({
    url: `/stack/resource`,
    method: 'get',
    params: { stackId: requestParams.stackId }
  })
}

consoleBackendService.deleteStack = function (requestParams) {
  return fetch({
    url: `/stack/${requestParams.stackId}`,
    method: 'delete',
  })
}

consoleBackendService.getStackInitializationStatus = function (requestParams) {
  return fetch({
    url: `/stack/initializationStatus`,
    method: 'get',
    params: { stackId: requestParams.stackId }
  })
}

consoleBackendService.stackInitializationRetry = function (requestParams) {
  return fetch({
    url: 'stack/initializationRetry',
    method: 'post',
    params: { stackId: requestParams.stackId }
  })
}

consoleBackendService.getStackFromBlueprintInitializationStatus = function (requestParams) {
  return fetch({
    url: `/stack/blueprintStackInitializationStatus`,
    method: 'get',
    params: { stackId: requestParams.stackId }
  })
}

consoleBackendService.activeRuns = function (requestParams = {}) {
  return fetch({
    url: 'stack/run',
    method: 'get',
    params: { namespaceId: requestParams.namespaceId }
  })
}

consoleBackendService.interactiveProcessInProgress = function (data) {
  return fetch({
    url: '/interactiveProcess/inProgress',
    method: 'post',
    data
  })
}

consoleBackendService.interactiveProcessFinished = function (data) {
  return fetch({
    url: '/interactiveProcess/finished',
    method: 'post',
    data
  })
}

consoleBackendService.stackResourcesImport = function (data) {
  return fetch({
    url: '/stack/resourcesImport',
    method: 'post',
    data
  })
}

consoleBackendService.stackResourcesImportStatus = function (requestParams) {
  return fetch({
    url: '/stack/resourcesImportStatus',
    method: 'get',
    params: {resourcesImportId: requestParams.resourcesImportId}
  })
}

consoleBackendService.stackResourcesImportApprove = function (requestParams) {
  return fetch({
    url: '/stack/resourcesImport/approve',
    method: 'put',
    params: {resourcesImportId: requestParams.resourcesImportId}
  })
}

consoleBackendService.stackResourcesImportCancel = function (requestParams) {
  return fetch({
    url: '/stack/resourcesImport/cancel',
    method: 'put',
    params: {resourcesImportId: requestParams.resourcesImportId}
  })
}

consoleBackendService.deleteStackResources = function (data) {
  return fetch({
    url: '/stack/resource',
    method: 'delete',
   data,
  })
}

consoleBackendService.getTerraformVersions = function () {
  return fetch({
    url: 'common/terraformVersions',
    method: 'get'
  });
};

consoleBackendService.getTerragruntVersions = function () {
  return fetch({
    url: 'common/terragruntVersions',
    method: 'get'
  });
};

consoleBackendService.getOpenTofuVersions = function () {
  return fetch({
    url: 'common/opentofuVersions',
    method: 'get'
  });
};

consoleBackendService.getPotentialStacks = function () {
  return fetch({
    url: '/potentialStack',
    method: 'get',
  })
}

consoleBackendService.stackExtendTTL = function (requestParams) {
  return fetch({
    url: `/stack/${requestParams.stackId}/extendTtl`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.stackDiscoveryConfiguration = function (requestParams) {
  return fetch({
    url: '/stackDiscoveryConfiguration',
    method: 'get',
    params: { namespaceId: requestParams?.namespaceId }
  })
}

consoleBackendService.createStackDiscoveryConfiguration = function (data) {
  return fetch({
    url: '/stackDiscoveryConfiguration',
    method: 'post',
    data: data
  })
}

consoleBackendService.deleteStackDiscoveryConfiguration = function (requestParams) {
  return fetch({
    url: `/stackDiscoveryConfiguration/${requestParams.stackDiscoveryConfigurationId}`,
    method: 'delete',
  })
}

consoleBackendService.getStackDiscoveryConfiguration = function (requestParams) {
  return fetch({
    url: `/stackDiscoveryConfiguration/${requestParams.stackDiscoveryConfigurationId}`,
    method: 'get'
  })
}

consoleBackendService.updateStackDiscoveryConfiguration = function (requestParams) {
  return fetch({
    url: `/stackDiscoveryConfiguration/${requestParams.stackDiscoveryConfigurationId}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.getStackDiscoveryEvents = function (requestParams) {
  return fetch({
    url: `/stackDiscoveryConfiguration/event`,
    method: 'get',
    params: requestParams
  })
}

////////////////////
// Outputs
////////////////////
consoleBackendService.getStackOutputs = function (requestParams) {
  return fetch({
    url: '/stack/output',
    method: 'get',
    params: { stackId: requestParams.stackId }
  })
}

consoleBackendService.getOutputSecretValue = function (data) {
  return fetch({
    url: `/stack/outputSecretValue`,
    method: 'post',
    data
  })
}

////////////////////
// Drift
////////////////////
consoleBackendService.getStackDriftDetails = function (requestParams) {
  return fetch({
    url: `/stack/driftDetails`,
    method: 'get',
    params: { stackId: requestParams.stackId, awsAccountId: requestParams.awsAccountId, azureSubscriptionId: requestParams.azureSubscriptionId, gcpProjectId: requestParams.gcpProjectId, minimalOutput: requestParams.minimalOutput }
  })
}

consoleBackendService.getAllStacksDriftDetails = function () {
  return fetch({
    url: `/stack/driftDetails`,
    method: 'get'
  })
}

consoleBackendService.triggerDriftDetection = function (data) {
  return fetch({
    url: `/stack/driftDetection`,
    method: 'post',
    data
  })
}

consoleBackendService.driftDetectionStatus = function (requestParams) {
  return fetch({
    url: `/stack/driftDetectionStatus`,
    method: 'get',
    params: { stackId: requestParams.stackId }
  })
}

consoleBackendService.driftRemediation = function (requestParams) {
  return fetch({
    url: '/stack/driftRemediation/',
    method: 'post',
    params: { stackId: requestParams.stackId},
    data: requestParams.data
  })
}

consoleBackendService.driftRemediationStatus = function (requestParams) {
  return fetch({
    url: `/stack/driftRemediationStatus`,
    method: 'get',
    params: { driftRemediationId: requestParams.driftRemediationId }
  })
}

////////////////
// Stack Dependencies
////////////////
consoleBackendService.getStackDependencies = function (requestParams) {
  return fetch({
    url: '/stack/dependency',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.addStackDependency = function (requestParams) {
  return fetch({
    url: '/stack/dependency',
    method: 'post',
    data: requestParams.data
  })
}

consoleBackendService.updateStackDependency = function (requestParams) {
  return fetch({
    url: `/stack/dependency/${requestParams.dependencyId}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.deleteStackDependency = function (requestParams) {
  return fetch({
    url: `/stack/dependency/${requestParams.dependencyId}`,
    method: 'delete',
  })
}

consoleBackendService.getStackDependenciesGraph = function (requestParams) {
  return fetch({
    url: '/stack/dependency/graph',
    method: 'get',
    params: requestParams
  })
}

///////////////////////////
// Deployment & Plan & Task
///////////////////////////
consoleBackendService.createDeployment = function (data) {
  return fetch({
    url: '/stack/deployment',
    method: 'post',
    data: data
  })
}

consoleBackendService.createPlan = function (data) {
  return fetch({
    url: '/stack/plan',
    method: 'post',
    data: data
  })
}

consoleBackendService.overrideDeploymentFailedPolicesAndContinue = function (requestParams) {
  return fetch({
    url: '/stack/deployment/approveFailedPolicies',
    method: 'put',
    params: { deploymentId: requestParams.deploymentId }
  })
}

consoleBackendService.approveDeploymentApply = function (requestParams) {
  return fetch({
    url: '/stack/deployment/approve',
    method: 'put',
    params: { deploymentId: requestParams.deploymentId }
  })
}

consoleBackendService.cancelDeployment = function (requestParams) {
  return fetch({
    url: '/stack/deployment/cancel',
    method: 'put',
    params: { deploymentId: requestParams.deploymentId }
  })
}

consoleBackendService.getStackDeployments = function (requestParams) {
  return fetch({
    url: '/stack/deployment',
    method: 'get',
    params: { stackId: requestParams.stackId }
  })
}

consoleBackendService.getDeployment = function (requestParams) {
  return fetch({
    url: `/stack/deployment/${requestParams.deploymentId}`,
    method: 'get'
  })
}

consoleBackendService.getLatestDeployments = function (requestParams) {
  return fetch({
    url: `/stack/latestDeployment`,
    method: 'get',
    params: { namespaceId: requestParams?.namespaceId }
  })
}

consoleBackendService.getDeploymentState = function (requestParams) {
  return fetch({
    url: '/stack/deploymentState',
    method: 'get',
    params: { deploymentId: requestParams.deploymentId }
  })
}

consoleBackendService.getPlanState = function (requestParams) {
  return fetch({
    url: '/stack/planState',
    method: 'get',
    params: { planId: requestParams.planId }
  })
}

consoleBackendService.getStackPlans = function (requestParams) {
  return fetch({
    url: '/stack/plan',
    method: 'get',
    params: { stackId: requestParams.stackId }
  })
}

consoleBackendService.getPlan = function (requestParams) {
  return fetch({
    url: `/stack/plan/${requestParams.planId}`,
    method: 'get'
  })
}

consoleBackendService.cancelPlan = function (requestParams) {
  return fetch({
    url: '/stack/plan/cancel',
    method: 'put',
    params: { planId: requestParams.planId }
  })
}

consoleBackendService.getTask = function (requestParams) {
  return fetch({
    url: `/stack/task/${requestParams.taskId}`,
    method: 'get'
  })
}

consoleBackendService.createTask = function (data) {
  return fetch({
    url: '/stack/task',
    method: 'post',
    data: data
  })
}

consoleBackendService.cancelTask = function (requestParams) {
  return fetch({
    url: '/stack/task/cancel',
    method: 'put',
    params: { taskId: requestParams.taskId }
  })
}

consoleBackendService.getStackTasks = function (requestParams) {
  return fetch({
    url: '/stack/task',
    method: 'get',
    params: { stackId: requestParams.stackId }
  })
}

////////////////////////////////
// Deployment & Plan & Task - V2
////////////////////////////////
consoleBackendService.getDeploymentStateV2 = function (requestParams) {
  return fetch({
    url: '/stack/deploymentState/v2',
    method: 'get',
    params: { deploymentId: requestParams.deploymentId }
  })
}

consoleBackendService.getPlanStateV2 = function (requestParams) {
  return fetch({
    url: '/stack/planState/v2',
    method: 'get',
    params: { planId: requestParams.planId }
  })
}

// Note this is already in the structure of V2 
consoleBackendService.getTaskState = function (requestParams) {
  return fetch({
    url: '/stack/taskState',
    method: 'get',
    params: { taskId: requestParams.taskId }
  })
}

////////////////
// Remote Run //
////////////////
consoleBackendService.getRemoteRunDetails = function (requestParams) {
  return fetch({
    url: '/stack/remoteRun/details',
    method: 'get',
    params: { runId: requestParams.runId }
  })
}

////////////////////
// VCS Provider
////////////////////
consoleBackendService.createVcsProvider = function (data) {
  return fetch({
    url: '/vcs/provider',
    method: 'post',
    data: data
  })
}

consoleBackendService.getVcsProviders = function (requestParams) {
  return fetch({
    url: '/vcs/provider/metadata',
    method: 'get'
  })
}

consoleBackendService.updateVcsProvider = function (requestParams) {
  return fetch({
    url: `/vcs/provider/${requestParams.providerId}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.deleteVcsProvider = function (requestParams) {
  return fetch({
    url: `/vcs/provider/${requestParams.providerId}`,
    method: 'delete',
  })
}

consoleBackendService.getGitlabWebhookSecret = function (requestParams) {
  return fetch({
    url: '/vcs/provider/gitlab/webhookSecret',
    method: 'get',
    params: { providerId: requestParams.providerId }
  })
}

consoleBackendService.getAzureDevopsWebhookSecret = function (requestParams) {
  return fetch({
    url: '/vcs/provider/azureDevops/webhookSecret',
    method: 'get',
    params: { providerId: requestParams.providerId }
  })
}

consoleBackendService.getGithubEnterpriseWebhookSecret = function (requestParams) {
  return fetch({
    url: '/vcs/provider/githubEnterpriseApp/webhookProperties',
    method: 'get',
    params: { providerId: requestParams.providerId }
  })
}

consoleBackendService.getBitbucketCloudWebhookSecret = function (requestParams) {
  return fetch({
    url: '/vcs/provider/bitbucket/webhookProperties',
    method: 'get',
    params: { providerId: requestParams.providerId }
  })
}

consoleBackendService.getBitbucketDataCenterWebhookSecret = function (requestParams) {
  return fetch({
    url: '/vcs/provider/bitbucketDataCenter/webhookProperties',
    method: 'get',
    params: { providerId: requestParams.providerId }
  })
}

consoleBackendService.getRepositories = function () {
  return fetch({
    url: '/vcs/repo',
    method: 'get',
  })
}

consoleBackendService.vcsValidateConnection = function (data) {
  return fetch({
    url: '/vcs/validateConnection',
    method: 'post',
    data: data
  })
}

consoleBackendService.vcsCollectRepositories = function (data) {
  return fetch({
    url: '/vcs/collectGitRepos',
    method: 'post',
    data: data
  })
}

consoleBackendService.vcsScanRepositories = function (data) {
  return fetch({
    url: '/vcs/scanGitRepos',
    method: 'post',
    data: data
  })
}

////////////////////
// Custom Model
////////////////////
consoleBackendService.createCustomModel = function (data) {
  return fetch({
    url: '/aws/customModel',
    method: 'post',
    data: data
  })
}

consoleBackendService.getCustomModels = function (requestParams) {
  return fetch({
    url: '/aws/customModelStatus',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.deleteCustomModel = function (requestParams) {
  return fetch({
    url: `/aws/customModel/${requestParams.customModelId}`,
    method: 'delete',
  })
}

////////////////////
// AWS Resources
////////////////////
consoleBackendService.getCollectedAwsResourceTypes = function (requestParams) {
  return fetch({
    url: '/aws/resourceType',
    method: 'get'
  })
}

consoleBackendService.getAwsResources = function (requestParams) {
  return fetch({
    url: '/aws/resource',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getAwsTagKey = function (requestParams) {
  return fetch({
    url: '/aws/tagKey',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getAllAwsResourceTypes = function (requestParams) {
  return fetch({
    url: '/common/awsResourceTypes',
    method: 'get',
    // params: requestParams
  })
}

consoleBackendService.getAwsRegions = function (requestParams) {
  return fetch({
    url: '/common/awsRegions',
    method: 'get',
    // params: requestParams
  })
}

consoleBackendService.findRelatedResources = function (data) {
  return fetch({
    url: '/aws/resource/findRelatedResources',
    method: 'post',
    data: data
  })
}

////////////////////
// Azure Custom Model
////////////////////
consoleBackendService.azureGetCustomModels = function (requestParams) {
  return fetch({
    url: '/azure/customModelStatus',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.azureCreateCustomModel = function (data) {
  return fetch({
    url: '/azure/customModel',
    method: 'post',
    data: data
  })
}

consoleBackendService.azureDeleteCustomModel = function (requestParams) {
  return fetch({
    url: `/azure/customModel/${requestParams.customModelId}`,
    method: 'delete',
  })
}

////////////////////
// Azure Resources
////////////////////

consoleBackendService.getAzureResources = function (requestParams) {
  return fetch({
    url: '/azure/resource',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.azureFindRelatedResources = function (data) {
  return fetch({
    url: '/azure/resource/findRelatedResources',
    method: 'post',
    data: data
  })
}

////////////////////
// GCP Custom Model
////////////////////
consoleBackendService.gcpGetCustomModels = function (requestParams) {
  return fetch({
    url: '/gcp/customModelStatus',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.gcpCreateCustomModel = function (data) {
  return fetch({
    url: '/gcp/customModel',
    method: 'post',
    data: data
  })
}

consoleBackendService.gcpDeleteCustomModel = function (requestParams) {
  return fetch({
    url: `/gcp/customModel/${requestParams.customModelId}`,
    method: 'delete',
  })
}

////////////////////
// GCP Resources
////////////////////

consoleBackendService.getGcpResources = function (requestParams) {
  return fetch({
    url: '/gcp/resource',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.gcpFindRelatedResources = function (data) {
  return fetch({
    url: '/gcp/resource/findRelatedResources',
    method: 'post',
    data: data
  })
}

////////////////////
// Organization
////////////////////
consoleBackendService.getSsoPotentialSession = function () {
  return fetch({
    url: '/iam/org/ssoPotentialSession',
    method: 'get'
  })
}

consoleBackendService.ssoSwitchSession = function (data) {
  return fetch({
    url: '/iam/org/ssoSwitchSession',
    method: 'post',
    data: data
  })
}

consoleBackendService.getCurrUserAllowedOrgs = function (requestParams) {
  return fetch({
    url: '/iam/org/userAllowedOrganization',
    method: 'get'
  })
}

consoleBackendService.getIdentityProviders = function (requestParams) {
  return fetch({
    url: '/iam/org/idpProvider',
    method: 'get'
  })
}

consoleBackendService.createIdentityProvider = function (data) {
  return fetch({
    url: '/iam/org/idpProvider',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateIdentityProvider = function (requestParams) {
  return fetch({
    url: `/iam/org/idpProvider/${requestParams.identityProviderId}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.deleteIdentityProvider = function (requestParams) {
  return fetch({
    url: `/iam/org/idpProvider/${requestParams.identityProviderId}`,
    method: 'delete',
  })
}

consoleBackendService.getCurrUserOrg = function () {
  return fetch({
    url: '/org',
    method: 'get'
  })
}

consoleBackendService.getOrgConfig = function () {
  return fetch({
    url: '/org/configuration',
    method: 'get'
  });
}

consoleBackendService.updateOrgConfig = function (data) {
  return fetch({
    url: '/org/configuration',
    method: 'put',
    data
  });
}

consoleBackendService.getCapabilities = function () {
  return fetch({
    url: `/org/capabilities`,
    method: 'get'
  })
}

consoleBackendService.getOrganizationsConnections = function () {
  return fetch({
    url: '/iam/org/connection',
    method: 'get'
  });
}

consoleBackendService.getOrganizationsTeams = function () {
  return fetch({
    url: '/iam/org/connectionTeams',
    method: 'get'
  });
}

consoleBackendService.getCustomAbacConfiguration = function () {
  return fetch({
    url: '/iam/org/customAbacConfiguration',
    method: 'get'
  });
}

consoleBackendService.createCustomAbacConfiguration = function (data) {
  return fetch({
    url: '/iam/org/customAbacConfiguration',
    method: 'post',
    data
  });
}

consoleBackendService.updateCustomAbacConfiguration = function (requestParams) {
  return fetch({
    url: `/iam/org/customAbacConfiguration/${requestParams.id}`,
    method: 'put',
    data: requestParams.data
  });
}

consoleBackendService.deleteCustomAbacConfiguration = function (requestParams) {
  return fetch({
    url: `/iam/org/customAbacConfiguration/${requestParams.id}`,
    method: 'delete',
  });
}

consoleBackendService.getSignInSettings = function () {
  return fetch({
    url: '/iam/org/signinConfiguration',
    method: 'get'
  });
}

consoleBackendService.updateSignInSettings = function (data) {
  return fetch({
    url: '/iam/org/signinConfiguration',
    method: 'put',
    data
  });
}

// Not part of organization, but in the same API route
consoleBackendService.getPersonalAccessToken = function () {
  return fetch({
    url: 'iam/org/session/pat',
    method: 'post',
  })
}

////////////////////
// Teams
////////////////////
consoleBackendService.getTeams = function (requestParams) {
  return fetch({
    url: '/iam/org/team',
    method: 'get'
  })
}

consoleBackendService.getTeam = function (requestParams) {
  return fetch({
    url: `/iam/org/team/${requestParams.teamId}`,
    method: 'get'
  })
}

consoleBackendService.createTeam = function (data) {
  return fetch({
    url: '/iam/org/team',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateTeam = function (requestParams) {
  return fetch({
    url: `/iam/org/team/${requestParams.teamId}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.deleteTeam = function (requestParams) {
  return fetch({
    url: `/iam/org/team/${requestParams.teamId}`,
    method: 'delete',
  })
}

consoleBackendService.getTeamMembers = function (requestParams) {
  return fetch({
    url: '/iam/org/teamUser',
    method: 'get',
    params: { teamId: requestParams.teamId }
  })
}

consoleBackendService.addTeamMember = function (data) {
  return fetch({
    url: '/iam/org/teamUser',
    method: 'post',
    data: data
  })
}

consoleBackendService.removeTeamMember = function (data) {
  return fetch({
    url: '/iam/org/teamUser',
    method: 'delete',
    data: data
  })
}

/////////////////////////
// Namespace permissions
/////////////////////////
consoleBackendService.getNamespacePermissions = function (requestParams) {
  return fetch({
    url: '/iam/org/namespacePermission',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.addPermissionToNamespace = function (data) {
  return fetch({
    url: '/iam/org/namespacePermission',
    method: 'post',
    data: data
  })
}

consoleBackendService.removePermissionFromNamespace = function (data) {
  return fetch({
    url: '/iam/org/namespacePermission',
    method: 'delete',
    data: data
  })
}

////////////////////
// Users
////////////////////
consoleBackendService.getOrgUserRole = function () {
  return fetch({
    url: 'iam/org/user/role',
    method: 'get'
  });
}

consoleBackendService.getOrgUsers = function (requestParams) {
  return fetch({
    url: '/iam/org/user',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.resetUserPassword = function (data) {
  return fetch({
    url: '/iam/org/user/password',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateUserProfile = function (data) {
  return fetch({
    url: '/iam/org/user/profile',
    method: 'put',
    data
  })
}

consoleBackendService.tradeSession = function (requestParams) {
  return fetch({
    url: '/iam/org/tradeSession',
    method: 'post',
    params: { desiredOrganizationId: requestParams.desiredOrganizationId }
  })
}

consoleBackendService.updateOrgUser = function (data) {
  return fetch({
    url: '/iam/org/user',
    method: 'put',
    data: data
  })
}

consoleBackendService.deleteOrgUser = function (data) {
  return fetch({
    url: '/iam/org/user',
    method: 'delete',
    data: data
  })
}

consoleBackendService.getOrgRunnersHeartbeat = function (requestParams) {
  return fetch({
    url: '/org/runner/heartbeat',
    method: 'get',
    params: { updatedInTheLastHours: requestParams.updatedInTheLastHours }
  })
}

consoleBackendService.getOrgCustomRole = function () {
  return fetch({
    url: 'iam/org/customRole',
    method: 'get'
  });
}

////////////////////
// MFA V2
////////////////////
consoleBackendService.orgUserMFAGenerate = function () {
  return fetch({
    url: 'iam/org/user/mfa/generate',
    method: 'post',
    data: {}
  });
}

consoleBackendService.orgUserMFAConfigure = function (data) {
  return fetch({
    url: 'iam/org/user/mfa/configure',
    method: 'post',
    data
  });
}

////////////////////
// Invite Users
////////////////////
consoleBackendService.pendingInviteOrgUsers = function () {
  return fetch({
    url: '/iam/org/pendingUser',
    method: 'get'
  })
}

consoleBackendService.inviteOrgUser = function (data) {
  return fetch({
    url: '/iam/org/user/invite',
    method: 'post',
    data: data
  })
}

consoleBackendService.updatePendingInviteOrgUser = function (data) {
  return fetch({
    url: '/iam/org/pendingUser',
    method: 'put',
    data: data
  })
}

consoleBackendService.deleteInviteOrgUser = function (data) {
  return fetch({
    url: '/iam/org/pendingUser',
    method: 'delete',
    data: data
  })
}

////////////////////
// Programmatic Users
////////////////////
consoleBackendService.getOrgProgrammaticUsers = function () {
  return fetch({
    url: '/iam/org/programmaticUser',
    method: 'get'
  })
}

consoleBackendService.createOrgProgrammaticUser = function (data) {
  return fetch({
    url: '/iam/org/programmaticUser',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateOrgProgrammaticUser = function (data) {
  return fetch({
    url: '/iam/org/programmaticUser',
    method: 'put',
    data: data
  })
}

consoleBackendService.deleteOrgProgrammaticUser = function (data) {
  return fetch({
    url: '/iam/org/programmaticUser',
    method: 'delete',
    data: data
  })
}

////////////////////
// Programmatic Token
////////////////////
consoleBackendService.getOrgProgrammaticToken = function (requestParams) {
  return fetch({
    url: '/iam/org/programmaticToken',
    method: 'get',
    params: { userName: requestParams.userName }
  })
}

consoleBackendService.createOrgProgrammaticToken = function (data) {
  return fetch({
    url: '/iam/org/programmaticToken',
    method: 'post',
    data: data
  })
}

consoleBackendService.deleteOrgProgrammaticToken = function (requestParams) {
  return fetch({
    url: `/iam/org/programmaticToken/${requestParams.programmaticTokenId}`,
    method: 'delete',
  })
}

////////////////////
// Team programmatic user
////////////////////
consoleBackendService.getOrgTeamProgrammaticUser = function (requestParams) {
  return fetch({
    url: '/iam/org/teamProgrammaticUser',
    method: 'get',
    params: { teamId: requestParams.teamId }
  })
}

consoleBackendService.addOrgTeamProgrammaticUser = function (data) {
  return fetch({
    url: '/iam/org/teamProgrammaticUser',
    method: 'post',
    data: data
  })
}

consoleBackendService.deleteOrgTeamProgrammaticUser = function (data) {
  return fetch({
    url: `/iam/org/teamProgrammaticUser`,
    method: 'delete',
    data
  })
}

////////////////////
// Custom Roles
////////////////////
consoleBackendService.getOrgCustomRoles = function () {
  return fetch({
    url: '/iam/org/customRole',
    method: 'get',
  })
}

consoleBackendService.createOrgCustomRole = function (data) {
  return fetch({
    url: '/iam/org/customRole',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateOrgCustomRole = function (requestParams) {
  return fetch({
    url: `/iam/org/customRole/${requestParams.id}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.deleteOrgCustomRole = function (requestParams) {
  return fetch({
    url: `/iam/org/customRole/${requestParams.id}`,
    method: 'delete',
  });
}

////////////////////
// Audit
////////////////////
consoleBackendService.getAuditEvents = function (requestParams) {
  return fetch({
    url: '/audit/event',
    method: 'get',
    params: { includeExternalInitiator: true, startDate: requestParams.startDate }
  })
}

////////////////////
// Variables
////////////////////
consoleBackendService.getOrgVariables = function (requestParams) {
  return fetch({
    url: '/variable',
    method: 'get',
    params: { orgOnly: true }
  })
}

consoleBackendService.getVariables = function (requestParams) {
  return fetch({
    url: '/variable',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.createVariable = function (data) {
  return fetch({
    url: '/variable',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateVariable = function (requestParams) {
  return fetch({
    url: `/variable/${requestParams.variableId}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.deleteVariable = function (requestParams) {
  return fetch({
    url: `/variable/${requestParams.variableId}`,
    method: 'delete'
  })
}

consoleBackendService.getVariableSecretValue = function (requestParams) {
  return fetch({
    url: `/variable/secretValue/${requestParams.variableId}`,
    method: 'get'
  })
}

////////////////////
// Subscriptions
////////////////////

consoleBackendService.getSubscriptions = function (requestParams) {
  return fetch({
    url: '/notification/subscription',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.createSubscription = function (data) {
  return fetch({
    url: '/notification/subscription',
    method: 'post',
    data: data
  })
}

consoleBackendService.deleteSubscription = function (requestParams) {
  return fetch({
    url: `/notification/subscription/${requestParams.subscriptionId}`,
    method: 'delete'
  })
}

////////////////////
// Notifications
////////////////////

consoleBackendService.getNotifications = function (requestParams) {
  return fetch({
    url: '/notification/endpoint',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.createNotification = function (data) {
  return fetch({
    url: '/notification/endpoint',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateNotification = function (requestParams) {
  return fetch({
    url: `/notification/endpoint/${requestParams.endpointId}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.deleteNotification = function (requestParams) {
  return fetch({
    url: `/notification/endpoint/${requestParams.endpointId}`,
    method: 'delete'
  })
}

consoleBackendService.validateNotification = function (data) {
  return fetch({
    url: '/notification/endpoint/validateConnection',
    method: 'post',
    data: data
  })
}

////////////////////
// Slack Apps
////////////////////

consoleBackendService.getSlackApps = function () {
  return fetch({
    url: '/notification/slackApp',
    method: 'get',
  })
}

consoleBackendService.createSlackApp = function (data) {
  return fetch({
    url: '/notification/slackApp',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateSlackApp = function (requestParams) {
  return fetch({
    url: `/notification/slackApp/${requestParams.notificationSlackAppId}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.deleteSlackApp = function (requestParams) {
  return fetch({
    url: `/notification/slackApp/${requestParams.notificationSlackAppId}`,
    method: 'delete'
  })
}

////////////////////
// SSH Keys
////////////////////
consoleBackendService.getSshKeys = function (requestParams) {
  return fetch({
    url: '/sshKey',
    method: 'get'
  })
}


consoleBackendService.createSshKey = function (data) {
  return fetch({
    url: '/sshKey',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateSshKey = function (requestParams) {
  return fetch({
    url: `/sshKey/${requestParams.id}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.setSshKeyAsPrimary = function (requestParams) {
  return fetch({
    url: `/sshKey/${requestParams.keyId}/setPrimary`,
    method: 'put'
  })
}

consoleBackendService.deleteSshKey = function (requestParams) {
  return fetch({
    url: `/sshKey/${requestParams.keyId}`,
    method: 'delete'
  })
}

////////////////////
// Terraform Modules
////////////////////
consoleBackendService.getTfModuleDiscovery = function (requestParams) {
  return fetch({
    url: '/tf/moduleDiscovery',
    method: 'get',
    params: requestParams
  })
}

////////////////////
// Terraform Providers
////////////////////
consoleBackendService.getTfProviderDiscovery = function (requestParams) {
  return fetch({
    url: '/tf/providerDiscovery',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getTfProviderOutput = function (requestParams) {
  return fetch({
    url: '/tf/providerOutput',
    method: 'get',
    params: requestParams
  })
}

////////////////////
// IAC Versions 
////////////////////
consoleBackendService.getIacVersions= function () {
  return fetch({
    url: '/tf/versionDiscovery',
    method: 'get',
  })
}

////////////////////
// Terraform Resources
////////////////////
consoleBackendService.getTfResources = function () {
  return fetch({
    url: '/tf/resource',
    method: 'get',
  })
}

consoleBackendService.requestTfResourceSupport = function (data) {
  return fetch({
    url: '/tf/import/support',
    method: 'post',
    data: data
  })
}

////////////////////
// Terraform Failed Policy Resources
////////////////////
consoleBackendService.getTfFailedPolicyResources = function () {
  return fetch({
    url: '/tf/failedPolicyResource',
    method: 'get',
  })
}

////////////////////
// Policies
////////////////////
consoleBackendService.getControlPolicies = function (requestParams) {
  return fetch({
    url: '/controlPolicy',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getControlPolicy = function (requestParams) {
  return fetch({
    url: `/controlPolicy/${requestParams.controlPolicyId}`,
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.createControlPolicy = function (data) {
  return fetch({
    url: '/controlPolicy',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateControlPolicy = function (requestParams) {
  return fetch({
    url: `/controlPolicy/${requestParams.controlPolicyId}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.deleteControlPolicy = function (requestParams) {
  return fetch({
    url: `/controlPolicy/${requestParams.controlPolicyId}`,
    method: 'delete'
  })
}

consoleBackendService.getControlPolicyNamespaceMapping = function (requestParams) {
  return fetch({
    url: '/controlPolicy/controlPolicyNamespaceMapping',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.createControlPolicyNamespaceMapping = function (data) {
  return fetch({
    url: '/controlPolicy/controlPolicyNamespaceMapping',
    method: 'post',
    data: data
  })
}

consoleBackendService.deleteControlPolicyNamespaceMapping = function (data) {
  return fetch({
    url: '/controlPolicy/controlPolicyNamespaceMapping',
    method: 'delete',
    data: data
  })
}

consoleBackendService.getControlPolicyMapping = function (requestParams) {
  return fetch({
    url: '/controlPolicy/controlPolicyMapping',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.createControlPolicyMapping = function (data) {
  return fetch({
    url: '/controlPolicy/controlPolicyMapping',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateControlPolicyMapping = function (data) {
  return fetch({
    url: '/controlPolicy/controlPolicyMapping',
    method: 'put',
    data: data
  })
}

consoleBackendService.deleteControlPolicyMapping = function (data) {
  return fetch({
    url: '/controlPolicy/controlPolicyMapping',
    method: 'delete',
    data: data
  })
}

consoleBackendService.getControlPoliciesGroups = function (requestParams) {
  return fetch({
    url: '/controlPolicyGroup',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getControlPolicyGroup = function (requestParams) {
  return fetch({
    url: `/controlPolicyGroup/${requestParams.controlPolicyGroupId}`,
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.createControlPolicyGroup = function (data) {
  return fetch({
    url: '/controlPolicyGroup',
    method: 'post',
    data: data
  })

}

consoleBackendService.updateControlPolicyGroup = function (requestParams) {
  return fetch({
    url: `/controlPolicyGroup/${requestParams.controlPolicyGroupId}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.deleteControlPolicyGroup = function (requestParams) {
  return fetch({
    url: `/controlPolicyGroup/${requestParams.controlPolicyGroupId}`,
    method: 'delete'
  })
}

consoleBackendService.getControlPolicyGroupMapping = function (requestParams) {
  return fetch({
    url: '/controlPolicyGroup/controlPolicyGroupMapping',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.deleteControlPolicyGroupMapping = function (data) {
  return fetch({
    url: '/controlPolicyGroup/controlPolicyGroupMapping',
    method: 'delete',
    data: data
  })
}

consoleBackendService.createControlPolicyGroupMapping = function (data) {
  return fetch({
    url: '/controlPolicyGroup/controlPolicyGroupMapping',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateControlPolicyGroupMapping = function (data) {
  return fetch({
    url: '/controlPolicyGroup/controlPolicyGroupMapping',
    method: 'put',
    data: data
  })
}

// consoleBackendService.getVariables = function (requestParams) {
//   return fetch({
//     url: '/variable',
//     method: 'get',
//     params: requestParams
//   })
// }



// consoleBackendService.deleteVariable = function (requestParams) {
//   return fetch({
//     url: `/variable/${requestParams.variableId}`,
//     method: 'delete'
//   })
// }

////////////////////
// Cloud Events
////////////////////

consoleBackendService.getAwdCloudEvents = function (requestParams) {
  return fetch({
    url: '/aws/event',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getAzureCloudEvents = function (requestParams) {
  return fetch({
    url: '/azure/event',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getGcpCloudEvents = function (requestParams) {
  return fetch({
    url: '/gcp/event',
    method: 'get',
    params: requestParams
  })
}

////////////////////
// Events Rules
////////////////////

consoleBackendService.getEventsRules = function (requestParams) {
  return fetch({
    url: '/aws/event/rule',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getEventsRule = function (requestParams) {
  return fetch({
    url: `/aws/event/rule/${requestParams.eventRuleId}`,
    method: 'get',
    params: requestParams
  })
}


consoleBackendService.createEventRule = function (data) {
  return fetch({
    url: '/aws/event/rule',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateEventRule = function (requestParams) {
  return fetch({
    url: `/aws/event/rule/${requestParams.eventRuleId}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.deleteEventRule = function (requestParams) {
  return fetch({
    url: `/aws/event/rule/${requestParams.eventRuleId}`,
    method: 'delete'
  })
}

////////////////////
// Reports
////////////////////

consoleBackendService.getSOC2Report = function (requestParams) {
  return fetch({
    url: '/report/soc',
    method: 'get',
    params: requestParams
  })
}

//////////////////////
// Terraform Registry
//////////////////////

consoleBackendService.getModules = function (requestParams) {
  return fetch({
    url: '/module',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getModule = function (requestParams) {
  return fetch({
    url: `/module/${requestParams.moduleId}`,
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.createModule = function (data) {
  return fetch({
    url: '/module',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateModule = function (requestParams) {
  return fetch({
    url: `/module/${requestParams.moduleId}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.deleteModule = function (requestParams) {
  return fetch({
    url: `/module/${requestParams.moduleId}`,
    method: 'delete'
  })
}

consoleBackendService.getModuleVersion = function (requestParams) {
  return fetch({
    url: `/module/${requestParams.moduleId}/version`,
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getModuleVersionSubmodule = function (requestParams) {
  return fetch({
    url: `/module/${requestParams.moduleId}/version/submodule`,
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getModuleVersionExample = function (requestParams) {
  return fetch({
    url: `/module/${requestParams.moduleId}/version/example`,
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getSsoSessions = function (requestParams) {
  return fetch({
    url: '/iam/org/sessionLog',
    method: 'get',
    params: requestParams
  });
};

//////////////////////
// Disaster Recovery
//////////////////////

consoleBackendService.getDisasterRecoveryConfigurations = function (requestParams) {
  return fetch({
    url: '/disasterRecovery/configuration',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getDisasterRecoveryConfiguration = function (requestParams) {
  return fetch({
    url: `/disasterRecovery/configuration/${requestParams.disasterRecoveryConfigurationId}`,
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.createDisasterRecoveryConfiguration = function (data) {
  return fetch({
    url: '/disasterRecovery/configuration',
    method: 'post',
    data: data
  })
}

consoleBackendService.updateDisasterRecoveryConfiguration = function (requestParams) {
  return fetch({
    url: `/disasterRecovery/configuration/${requestParams.disasterRecoveryConfigurationId}`,
    method: 'put',
    data: requestParams.data
  })
}

consoleBackendService.deleteDisasterRecoveryConfiguration = function (requestParams) {
  return fetch({
    url: `/disasterRecovery/configuration/${requestParams.disasterRecoveryConfigurationId}`,
    method: 'delete'
  })
}

consoleBackendService.getDisasterRecoverySnapshots = function (requestParams) {
  return fetch({
    url: '/disasterRecovery/snapshot',
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getDisasterRecoverySnapshot = function (requestParams) {
  return fetch({
    url: `/disasterRecovery/snapshot/${requestParams.snapshotId}`,
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getDisasterRecoverySnapshotResourcesAWS = function (requestParams) {
  return fetch({
    url: `/disasterRecovery/snapshot/aws/resource`,
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getDisasterRecoveryResourceMetadataAWS = function (requestParams) {
  return fetch({
    url: `/disasterRecovery/aws/resource/metadata`,
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getDisasterRecoverySummaryAWS = function (requestParams) {
  return fetch({
    url: `/disasterRecovery/awsSummary`,
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getDisasterRecoverySnapshotResourcesGCP = function (requestParams) {
  return fetch({
    url: `/disasterRecovery/snapshot/gcp/resource`,
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getDisasterRecoveryResourceMetadataGCP = function (requestParams) {
  return fetch({
    url: `/disasterRecovery/gcp/resource/metadata`,
    method: 'get',
    params: requestParams
  })
}

consoleBackendService.getDisasterRecoverySummaryGCP = function (requestParams) {
  return fetch({
    url: `/disasterRecovery/gcpSummary`,
    method: 'get',
    params: requestParams
  })
}

export default consoleBackendService;
