import { createContext, useState, useEffect, useContext } from 'react';
import AuthDataContext from './AuthDataContext';
import Loading from 'components/shared-components/Loading';
import consoleBackendService from 'services/ConsoleBackendService';
import utils_lodash from 'lodash';
import Utils from 'utils';
import { AWS_GLOBAL_AND_REGIONAL_SERVICES, AWS_GLOBAL_RESOURCE_TYPES, AWS_GLOBAL_SERVICES, AWS_SERVICE_TYPE, CLOUD_CREDS_PROVIDER_TYPE, CLOUD_CREDS_TYPE, ROLE, VCS_PROVIDER } from 'constants/AppConstant';

const AppDataContext = createContext();

export const AppDataProvider = ({ children }) => {
    const { orgUserRole } = useContext(AuthDataContext);
    const [vcsProviders, setVcsProviders] = useState([]);
    const [awsResourceTypes, setAwsResourceTypes] = useState([])
    const [awsServices, setAwsServices] = useState([]);
    const [terraformVersions, setTerraformVersions] = useState([]);
    const [terragruntVersions, setTerragruntVersions] = useState([]);
    const [opentofuVersions, setOpentofuVersions] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [cloudCredsDiscovery, setCloudCredsDiscovery] = useState({});
    const [capabilities, setCapabilities] = useState([]);
    const [signInSettings, setSignInSettings] = useState({});
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // only fetch if role is admin/viewer
    const fetchSignInSettings = orgUserRole === ROLE.ADMIN.key || orgUserRole === ROLE.VIEWER.key;

    const loadData = (updateLoading = true) => {
        // if updateLoading is set to false we don't update the isLoading state, so the render of this context provider
        // will not refresh the children which will cause rerender of whole page(s) and loosing a page local state
        // specific pages that want to use this loadData() function without loosing its rendered page should call it
        // with loadData(false) e.g: cloudCredsList
        if (updateLoading) {
            setIsLoading(true);
        }
        
        const vcsProvidersPromise = consoleBackendService.getVcsProviders();
        const awsResourceTypesPromise = consoleBackendService.getCollectedAwsResourceTypes();
        const terraformVersionsPromise = consoleBackendService.getTerraformVersions();
        const terragruntVersionsPromise = consoleBackendService.getTerragruntVersions();
        const opentofuVersionsPromise = consoleBackendService.getOpenTofuVersions();
        const organizationsPromise = consoleBackendService.getOrganizationsConnections();
        // credentials for dashboard redirect and selection on organiztion type of multiple providers
        const awsCredsPromise = consoleBackendService.getAwsCreds();
        const azureCredsPromise = consoleBackendService.getAzureCreds();
        const gcpCredsPromise = consoleBackendService.getGcpCreds();
        // capabilities
        const capabilitiesPromise = consoleBackendService.getCapabilities();
        // sign in settings (conditional upon role)
        let usersPromise = Promise.resolve([]);
        let signInSettingsPromise = Promise.resolve({});
        if (fetchSignInSettings) {
            // get users and sign in settings if role is admin/viewer
            signInSettingsPromise = consoleBackendService.getSignInSettings();
            usersPromise = consoleBackendService.getOrgUsers();
        }

        Promise.all([vcsProvidersPromise, awsResourceTypesPromise, terraformVersionsPromise, terragruntVersionsPromise, opentofuVersionsPromise, organizationsPromise, awsCredsPromise, azureCredsPromise, gcpCredsPromise, capabilitiesPromise, signInSettingsPromise, usersPromise]).then((responses) => {
            const vcsProvidersRes = responses[0].response.items || [];
            const awsResourceTypesRes = responses[1].response.items;
            let terraformVersionsRes = responses[2].response.items?.[0]?.terraformVersions || [];
            let terragruntVersionsRes = responses[3].response.items?.[0]?.terragruntVersions || [];
            let opentofuVersionsRes = responses[4].response.items?.[0]?.opentofuVersions || [];
            const organizationsRes = responses[5].response.items || [];
            const awsAccountList = responses[6].response.items || [];
            const azureAccountList = responses[7].response.items || [];
            const gcpAccountList = responses[8].response.items || [];
            const capabilitiesRes = responses[9].response?.items?.[0]?.capabilities || [];
            // conditional upon role
            const signInSettingsRes = fetchSignInSettings ? responses[10]?.response?.items?.[0] || {} : {};
            const usersRes = fetchSignInSettings ? responses[11]?.response?.items || [] : [];

            terraformVersionsRes = Utils.semVerTransformVersions(terraformVersionsRes);
            terragruntVersionsRes = Utils.semVerTransformVersions(terragruntVersionsRes);
            opentofuVersionsRes = Utils.semVerTransformVersions(opentofuVersionsRes);

            const resourceTypesNames = awsResourceTypesRes.map(item => ({
                key: item.name,
                fullName: item.name,
                service: item.service,
                isCodeGenerationSupported: item.isCodeGenerationSupported,
                displayName: item.name.substring(5), // remove the 'AWS::' prefix
                isGlobal: AWS_GLOBAL_RESOURCE_TYPES.some((rt) => item.name.includes(rt)) // check if resource type counts as global
            }));
            const sortedTypes = utils_lodash.sortBy(resourceTypesNames, 'displayName');

            // Build aws services data.
            const groupByService = utils_lodash.groupBy(awsResourceTypesRes, item => {
                return item.service;
            });

            let awsServices = [];
            for (const [key, value] of Object.entries(groupByService)) {
                // check which service type if global or regional or both
                let serviceType = AWS_SERVICE_TYPE.REGIONAL; // default

                if (AWS_GLOBAL_SERVICES.includes(key)) {
                    serviceType = AWS_SERVICE_TYPE.GLOBAL;
                } else if (AWS_GLOBAL_AND_REGIONAL_SERVICES.includes(key)) {
                    serviceType = AWS_SERVICE_TYPE.GLOBAL_AND_REGIONAL;
                }

                awsServices.push({ key: key, displayName: key, serviceType });
            }

            const sortedServices = utils_lodash.sortBy(awsServices, 'displayName');
            // filter out providers such as App configuration, can add more in the future
            const vcsProvidersWithoutApps = vcsProvidersRes.filter(p => p.type !== VCS_PROVIDER.GITHUB_ENTERPRISE_APP);

            // credentials from providers filtered by type discovery and sorted by createdAt property
            const cloudCredsDiscoveryMap = {
                [CLOUD_CREDS_PROVIDER_TYPE.AWS]: awsAccountList.filter((a) => a.type === CLOUD_CREDS_TYPE.visibility.value).sort((a,b)=> a.name.localeCompare(b.name)),
                [CLOUD_CREDS_PROVIDER_TYPE.AZURE]: azureAccountList.filter((a) => a.type === CLOUD_CREDS_TYPE.visibility.value).sort((a,b)=> a.name.localeCompare(b.name)),
                [CLOUD_CREDS_PROVIDER_TYPE.GCP]: gcpAccountList.filter((a) => a.type === CLOUD_CREDS_TYPE.visibility.value).sort((a,b)=> a.name.localeCompare(b.name))
            };

            setAwsServices(sortedServices);
            setAwsResourceTypes(sortedTypes);
            setVcsProviders(vcsProvidersWithoutApps);
            setTerraformVersions(terraformVersionsRes);
            setTerragruntVersions(terragruntVersionsRes);
            setOpentofuVersions(opentofuVersionsRes);
            setOrganizations(organizationsRes);
            setCloudCredsDiscovery(cloudCredsDiscoveryMap);
            setCapabilities(capabilitiesRes);
            setSignInSettings(signInSettingsRes);
            setUsers(usersRes);
            if (updateLoading) {
                setIsLoading(false);
            }

        }).catch(function (err) {
            // TODO - handle error
            console.log("Error", err);
            if (updateLoading) {
                setIsLoading(false);
            }
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    return <AppDataContext.Provider value={{
        vcsProviders, awsResourceTypes, awsServices, terraformVersions, terragruntVersions, opentofuVersions, organizations, cloudCredsDiscovery, capabilities, signInSettings, setSignInSettings, users, isLoading, loadData
    }}>
        {/* We wait until all context data is loaded then render the app-pages Routes */}
        {isLoading ? (<Loading cover="content" />) : children}
    </AppDataContext.Provider>
}

export default AppDataContext;
